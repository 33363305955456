import React, { useEffect, useState } from "react";
import { navigate, Link } from "gatsby";
import styled from "styled-components";
import PropTypes from "prop-types";
import useGlobal from "../store";
import Page from "../components/Page";
import FormBanner from "../components/styles/FormBanner";
import Container from "../components/styles/Container";
import Heading from "../components/styles/Heading";
import Box from "../components/styles/Box";
import { EmailInput } from "../components/styles/LargeInput";
import SubmitButton from "../components/styles/SubmitButton";
import { onEnter } from "../utils/helpers";

const StyledLink = styled(Link)`
  font-family: "Open Sans", sans-serif;
  margin-left: 15px;
`;

const LoginPage = ({ location }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [state, actions] = useGlobal();
  const { setToken } = actions;
  const { token } = state;

  const login = async () => {
    await setToken({ username, password });
  };

  useEffect(() => {
    if (token) {
      navigate(`/garage${location.search}`);
    }
  }, [location, token]);

  return (
    <Page>
      <FormBanner>
        <Container>
          <Box py="50px">
            <Heading my={3} fontSize={6} mx="auto">
              Login
            </Heading>
            <br />
            <EmailInput
              id="username"
              onChange={e => setUsername(e.target.value)}
              onKeyUp={e => onEnter(e, login)}
              placeholder="Email"
              value={username}
              type="text"
              color="black"
              width="250px"
            />
            <br />
            <EmailInput
              id="password"
              onChange={e => setPassword(e.target.value)}
              onKeyUp={e => onEnter(e, login)}
              placeholder="Password"
              value={password}
              type="password"
              color="black"
              width="250px"
            />
            <Box my={6}>
              <SubmitButton disabled={!(username && password)} onClick={login}>
                Submit
              </SubmitButton>
            </Box>
            <Box my={6}>
              <StyledLink to="/register">Register</StyledLink>
              <StyledLink to="/reset-password">Forgot Password?</StyledLink>
            </Box>
          </Box>
        </Container>
      </FormBanner>
    </Page>
  );
};

LoginPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired
};

export default LoginPage;
